import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { TWorkBlockDetailsContent } from '../WorkBlockDetailsContainer'
import { ReactComponent as PenIconComponent } from '../../../../../../assets/images/edit-work-block-pen-icon.svg';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import { useAppSelector } from '../../../../../../app/store';
import { getDateAndTimeWithoutYear } from '../../../../../../shared/utils/dateFormat';
import { ETaskStatus } from '../../stage-tasks/stageTasks.interface';
import { isTaskAssignToThisWorkBlock } from '../../../../../../shared/utils/utils';
import WorkBlockDetailsEmptyTasksState from './empty-tasks-state/WorkBlockDetailsEmptyTasksState';
import WorkBlockDetailsTasksList from './WorkBlockDetailsTasksList';
import "./WorkBlockDetails.scss";
import { useTranslation } from 'react-i18next';

interface IWorkBlockDetailsProps {
  setViewType: Dispatch<SetStateAction<TWorkBlockDetailsContent>>
}

const WorkBlockDetails: FunctionComponent<IWorkBlockDetailsProps> = ({ setViewType }) => {
  const { workBlockForEdit } = useAppSelector(store => store.workBlockReducer);
  const { allTasks } = useAppSelector(store => store.StageTasksReducer);
  const relatedTasksToDisplay = !!workBlockForEdit?.id ? allTasks.filter(task => task.status !== ETaskStatus.DELETED && isTaskAssignToThisWorkBlock(workBlockForEdit.id!, workBlockForEdit?.workTime, task?.workBlockId, task?.workBlockInstance)) : [];
  const { t } = useTranslation();

  const displayRelatedTasksContent = () => {
    if (!relatedTasksToDisplay || relatedTasksToDisplay.length <= 0) {
      return <WorkBlockDetailsEmptyTasksState />
    }
    return <WorkBlockDetailsTasksList tasksToDisplay={relatedTasksToDisplay} />
  }

  return (
    <div className='work-block-details-view' data-testid="work-block-details-view">
      <div className='work-block-details'>
        <AppButton id="work-block-view-switch-to-edit-mode" data-testid="switch-to-WB-form-view" className='switch-to-edit-work-block-form' onClick={() => setViewType("showForm")}>
          <PenIconComponent className='pen-icon' /> {t("workBlockDetailsSwitchToEditFormButton")}
        </AppButton>
        <div className='work-block-name'>{workBlockForEdit?.name}</div>
        {workBlockForEdit?.workTime && <div data-testid="work-block-date" className='work-block-date'>{getDateAndTimeWithoutYear(workBlockForEdit?.workTime)}</div>}
      </div>
      {displayRelatedTasksContent()}
    </div>
  )
}

export default WorkBlockDetails