import React, { Fragment, FunctionComponent, SyntheticEvent } from 'react'
import { ETaskFormType, ETaskStatus, IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../app/store'
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store'
import { getTimeRange } from '../../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/TasksList.utils'
import { isTaskAssignToThisWorkBlock } from '../../../../shared/utils/utils'
import { setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store'
import { ReactComponent as WorkBlockIconComponent } from '../../../../assets/images/work-block-icon.svg';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store'
import { onPlaceUnscheduledTask } from '../../plan.utils'
import SingleTaskItemContainer from '../../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer'
import './PlanDayScheduledWorkBlock.scss';

interface IPlanDayScheduledWorkBlockProps {
  workBlock: IMessageDataTask
}

const PlanDayScheduledWorkBlock: FunctionComponent<IPlanDayScheduledWorkBlockProps> = ({ workBlock }) => {
  const { allTasks } = useAppSelector(store => store.StageTasksReducer);
  const { plannerMode, currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClickWorkBlock = (event: SyntheticEvent) => {
    if (plannerMode && currentTaskPlacement) {
      event.stopPropagation();
      handleTaskScheduling();
    } else {
      dispatch(setWorkBlockForEdit(workBlock));
      dispatch(setShouldOpenWorkBlockDetails(true));
    }
  }

  const onClickTask = (event: SyntheticEvent, task: IMessageDataTask) => {
    if (plannerMode && currentTaskPlacement) {
      event.stopPropagation();
      handleTaskScheduling();
    } else {
      event.stopPropagation();
      dispatch(setSelectedMainTaskForEditing(task));
      dispatch(setShouldOpenAddEditTaskFrom(task.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
    }
  }

  const handleTaskScheduling = () => {
    switch (plannerMode) {
      case EPlannerMode.TIMEPICKER:
      case EPlannerMode.UNSCHEDULEDTASKSPLACER:
        if(currentTaskPlacement?.isEvent) return;
        onPlaceUnscheduledTask(new Date(workBlock.workTime!), workBlock.id, workBlock.name || t("workBlockDefaultName"), !!workBlock.workTimeRecurrenceType);
        break;
      default:
        break;
    }
  }

  const assignmentsTasks = [...allTasks, currentTaskPlacement || {} as IMessageDataTask]
  .filter(task => task.status !== ETaskStatus.DELETED && isTaskAssignToThisWorkBlock(workBlock.id!, workBlock.workTime || "", task?.workBlockId, task?.workBlockInstance))

  return (
    <div className='plan-day-scheduled-work-block' onClick={(e) =>{ if(!assignmentsTasks || assignmentsTasks.length === 0) onClickWorkBlock(e)}}>
      <section className='plan-day-scheduled-work-block--header'onClick={(e) =>{ if(!!assignmentsTasks && assignmentsTasks.length > 0) onClickWorkBlock(e)}}>
        <h1 className='workBlock-title'>{workBlock?.name || t("workBlockDefaultName")}</h1>
        <div className='pen-and-time-range'>
          {workBlock?.workTime && <div className='work-block-time-range'><WorkBlockIconComponent className='work-block-icon' />&nbsp;&nbsp;{getTimeRange(new Date(workBlock.workTime), workBlock.duration, true)}</div>}
        </div>
      </section>
      <section className='plan-day-scheduled-work-block--tasks'>
        {assignmentsTasks
          .map((t, i) =>
            <Fragment key={t.id}>
              <SingleTaskItemContainer
                shouldHideOnComplete={false}
                singleTask={t}
                shouldDisplayFirstTagColor={true}
                shouldDisplayActionButtons={false}
                onClick={(e) => onClickTask(e, t)}
              />
            </Fragment>
          )
        }
      </section>
    </div>
  )
}

export default PlanDayScheduledWorkBlock