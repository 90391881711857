import React, { FunctionComponent } from 'react'
import { getItemFromSessionOrLocalStorage } from '../../../../shared/utils/getItemFromSessionOrLocalStorage'
import { isNewSignUpFlowLocalStorageKey } from '../../../constants'
import { PhoneNumberVerification } from './PhoneNumberVerification'
import NewPhoneNumberVerification from '../../shorten-onboarding-flow/new-phone-verification/NewPhoneNumberVerification'

interface IProps {
  isLoginWithPhoneFlow?: boolean;
}

const PhoneNumberVerificationWrapper: FunctionComponent<IProps> = ({ isLoginWithPhoneFlow}) => {
  const renderContent = () => {
    if (getItemFromSessionOrLocalStorage<boolean>(isNewSignUpFlowLocalStorageKey)) {
      return <NewPhoneNumberVerification isLoginWithPhoneFlow={isLoginWithPhoneFlow} />
    }
    return <PhoneNumberVerification isLoginWithPhoneFlow={isLoginWithPhoneFlow} />
  }

  return (
    renderContent()
  )
}

export default PhoneNumberVerificationWrapper