import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { createOrContinueSession } from './chat.store';
import { ResizableContainer } from './resizable-container/ResizableContainer';
import './ChatWrapper.scss';
import WelcomeScreen from './welcome-screen/WelcomeScreen';
import ProductTour from '../product-tour/ProductTour';
import { getItemFromSessionOrLocalStorage } from '../../shared/utils/getItemFromSessionOrLocalStorage';
import { isNewSignUpFlowLocalStorageKey, isSignUpLocalStorageKey, shouldStayLoggedInLocalStorageKey } from '../../app/constants';
import { setShouldDisplayProductTour } from '../../shared/store/shared.store';
import { moveDataFromSessionToLocalStorage } from '../../shared/utils/moveDataFromSessionToLocalStorage';

export const ChatWrapper: FunctionComponent = () => {
  const { isComeFromRegistration, shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  // this carousel open only after registration
  const [isWelcomeScreenOpen, setIsWelcomeScreenOpen] = useState(!(getItemFromSessionOrLocalStorage<boolean>(isNewSignUpFlowLocalStorageKey)) && isComeFromRegistration);
  const componentShouldLoad = useRef(true);
  const dispatch = useAppDispatch();

  const onStartSession = useCallback(() => {
    // call createOrContinueSession Api
    dispatch(createOrContinueSession());
  }, [dispatch])
  
  const defaultStayLoggedIn = () => {
    // default to stay signed in
    localStorage.setItem(shouldStayLoggedInLocalStorageKey, JSON.stringify(true))
    moveDataFromSessionToLocalStorage();
  };

  useEffect(() => {
    if (componentShouldLoad.current) {
      componentShouldLoad.current = false;
      onStartSession();
    }
    // first time, if the user sign up by the shorten onboarding flow
    if(getItemFromSessionOrLocalStorage<boolean>(isNewSignUpFlowLocalStorageKey) && getItemFromSessionOrLocalStorage<boolean>(isSignUpLocalStorageKey)) {
      defaultStayLoggedIn();
      localStorage.removeItem(isSignUpLocalStorageKey);
      localStorage.removeItem(isNewSignUpFlowLocalStorageKey);
      dispatch(setShouldDisplayProductTour(true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStartSession]);

  return (
    <div className="chat-wrapper">
      {!isWelcomeScreenOpen && <ResizableContainer />}
      {/* This carousel will be displayed only after registration. */}
      <WelcomeScreen isWelcomeScreenOpen={isWelcomeScreenOpen} setIsWelcomeScreenOpen={setIsWelcomeScreenOpen} />
      {shouldDisplayProductTour && <ProductTour />}
    </div>
  )
}