import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useApiData } from '../../../../shared/hooks/useApiData';
import { AddPhoneRegistrationForm, IUser } from '../../auth.interfaces';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useLocalStorage } from '../../../../shared/utils/useLocalStorage';
import { APP_ROUTING_PATHS, isSignUpLocalStorageKey, userInfoLocalStorageKey, userPhoneCountryTwoLetterCodeLocalStorageKey, userPhoneNumberLocalStorageKey } from '../../../constants';
import { disallowedCountries, isDesktopView, supportedCountries, supportedCountriesInternal } from '../../../../shared/utils/utils';
import { getItemFromSessionOrLocalStorage } from '../../../../shared/utils/getItemFromSessionOrLocalStorage';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';
import { sendOtpReqAction, updatePhoneNumberReqAction } from '../../auth.store';
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { AppSelect } from '../../../../shared/components/app-select/AppSelect';
import PhoneInput from 'react-phone-number-input/input';
import { ContactUs } from '../../register/contact-us/ContactUs';
import en from 'react-phone-number-input/locale/en.json'
import { handleLogOut } from '../../../../shared/utils/logOut';
import phoneMascotImg from '../../../../assets/images/mascot/phone-mascot.png';
import './NewAddUserPhoneRegistrationForm.scss';

const NewAddUserPhoneRegistrationForm = () => {
  const { control, handleSubmit, setFocus, formState, reset, setValue } = useForm<AddPhoneRegistrationForm>({
    defaultValues: new AddPhoneRegistrationForm()
  });
  const { sendOtpRes, registrationRes } = useAppSelector(store => store.authReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [, setPhoneNumber] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const [, setPhoneCountryTwoLetterCode] = useLocalStorage<string | null>(userPhoneCountryTwoLetterCodeLocalStorageKey, null);
  const isInternalUser = userAppData?.data?.user?.isInternalUser || registrationRes?.data?.isInternalUser || userInfo?.isInternalUser || false;
  const supportedCountriesList = isInternalUser ? supportedCountriesInternal : supportedCountries;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resetPhoneTimer = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [country, setCountry] = useState<string>('US');
  const navigate = useNavigateWithSearchParams();
  const isComeFromSignUp = !!getItemFromSessionOrLocalStorage(isSignUpLocalStorageKey);

  useEffect(() => {
    // set auto focus on the userPhone input
    if (isDesktopView()) setFocus(`userPhone`);

    return () => {
      // clear Timeout when the component destroyed
      if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    }
  }, [setFocus])

  // concat the user phone number to a string and send to the server.
  const onSubmit = useCallback((formData: AddPhoneRegistrationForm) => {
    setPhoneCountryTwoLetterCode(country);
    const phoneNumber = `${formData.userPhone.replaceAll('-', '').trim()}`;
    setPhoneNumber(phoneNumber);
    dispatch(sendOtpReqAction({ phoneNumber: `${phoneNumber}` }));
  }, [dispatch, country, setPhoneNumber, setPhoneCountryTwoLetterCode]);

  // reset and set focus the phone text-box input on Rejected after 500ms
  const resetPhoneInput = () => {
    if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    resetPhoneTimer.current = setTimeout(() => {
      setFocus('userPhone');
      reset({ 'userPhone': '' });
    }, 500);
  }

  useApiData(sendOtpRes, {
    onFulfilled(sendOtpResData) {
      if (sendOtpResData) {
        navigate(APP_ROUTING_PATHS.PHONE_VERIFICATION);
      }
    },
    //  reset phone input when registration API rejected
    onRejected() {
      setShouldDisplayError(true);
      resetPhoneInput();
    },
  });

  const handleUnsupportedCountry = useCallback(() => {
    if (supportedCountriesList.includes(country)) return;
    dispatch(updatePhoneNumberReqAction({ phoneCountryTwoLetterCode: country }));
  }, [country, dispatch, supportedCountriesList]);

  useEffect(() => {
    handleUnsupportedCountry();
  }, [handleUnsupportedCountry]);

  const handleChangeCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(event.target.value);
    setValue('userPhone', "", { shouldValidate: true });
  }

  const getCountriesSorted = () => {
    const allowedCountries = getCountries().filter(c => !disallowedCountries.includes(c));
    const supportedCountries = allowedCountries.filter(c => supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
    const allowedCountriesWithoutSupported = allowedCountries.filter(c => !supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
    return supportedCountries.concat(allowedCountriesWithoutSupported);
  }

  return (
    <div className="add-phone-registration-page add-phone-form-page new-add-phone-form-page shorten-onboarding-flow auth-screen" id="update-phone-page" data-testid="update-phone-page">
      <ContentFrameWrapper className='add-phone-frame-wrapper'>
        <form className='scroll-y-container-hidden-scroll-bar' id="update-phone-form" onSubmit={handleSubmit(onSubmit)} data-testid="update-phone-form">
          <div className='update-phone-form-content'>
            <div className='title-container'>
              <img src={phoneMascotImg} alt="mascot" className='mascot' />
              <h1 className='title'>{t('phoneRegistrationScreenTitleNewFlow')}</h1>
            </div>
            <p className='add-phone-registration-permission-text add-phone-registration-permission-text--main'>{t("phoneRegistrationScreenParagraph1NewFlow")}</p>
            <div className="registration-input-container">
              <AppSelect className='phone-registration-container' value={country} onChange={handleChangeCountry} options={getCountriesSorted().map(c => ({ value: c, label: `+${getCountryCallingCode(c)} ${en[c]}`, selectedLabel: `+${getCountryCallingCode(c)}`, className: `country-initials-${c.toLocaleLowerCase()}` }))} />
              {(supportedCountriesList.includes(country)) && <>
                <Controller
                  name='userPhone'
                  control={control}
                  rules={{ validate: (value) => isValidPhoneNumber(`${value}`) }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      id={`user-phone-input`}
                      data-testid={`user-phone-input`}
                      className={`register-input ${formState.isValid && 'valid-mode'} ${shouldDisplayError && 'red-border-error'}`}
                      placeholder='XXX-XXX-XXXX'
                      value={value}
                      onChange={onChange}
                      maxLength={15 - (getCountryCallingCode(country as any)?.length || 0)}
                      country={country as any}
                    />
                  )}
                />
                {/* if there is error message from the server display it, else display static message */}
                {shouldDisplayError && <small className='error' data-testid="update-phone-error">{t("enterValidPhoneNumberError")}</small>}
              </>}
            </div>
            <button className='auth-next-btn' form="update-phone-form" id="update-phone-form-submit" data-testid="update-phone-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted} >{t('addPhoneRegistrationScreenSendBtnText')}</button>
            {(supportedCountriesList.includes(country)) && 
            <p className='agreement-paragraph' data-testid="update-phone-agreement">
              {t("phoneRegistrationScreenParagraph2NewFlow")}
            </p>}
            {!supportedCountriesList.includes(country) && <>
              <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText3")}</p>
              <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText4")}</p>
              <p className='add-phone-registration-permission-text'>{userInfo?.email}</p>
            </>}
          </div>
          {(supportedCountriesList.includes(country)) ? <div className="next-container">
          </div> : <ContactUs />}
        </form>
      </ContentFrameWrapper>
      <Link to={isComeFromSignUp ? APP_ROUTING_PATHS.SIGN_UP : APP_ROUTING_PATHS.SIGN_IN} onClick={() => handleLogOut(false, true)} className='back-link'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}

export default NewAddUserPhoneRegistrationForm