import { useState } from "react";
import { AppPhoneControl, IOnChangeEvent } from "../../../../shared/components/app-phone-control/AppPhoneControl"
import { ContentFrameWrapper } from "../../../../shared/components/content-frame-wrapper/ContentFrameWrapper"
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTING_PATHS, EMagiLinkLoginPageViewType, loginTypeStorageKey, privacyPolicyLink, registrationParametersStorageKey, termsOfServiceLink, userPhoneCountryTwoLetterCodeLocalStorageKey, userPhoneNumberLocalStorageKey } from "../../../constants";
import './LoginWithPhonePage.scss';
import { useAppDispatch, useAppSelector } from "../../../store";
import { requestMagicLink } from "../../auth.store";
import { baseUrl } from "../Login";
import { EAPIStatus } from "../../../../shared/api/models";
import { isEmbeddedBrowser } from "../../auth.utils";
import { useLocalStorage } from "../../../../shared/utils/useLocalStorage";
import PhoneNumberVerificationWrapper from "../../register/phone-number-verification/PhoneNumberVerificationWrapper";

export const LoginWithPhonePage = () => {
    const { magicLinkRes } = useAppSelector(store => store.authReducer);
    const { t } = useTranslation();
    const [viewType, setViewType] = useState<EMagiLinkLoginPageViewType>(EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK);
    const urlParams = new URLSearchParams(window.location.search);
    const isInternalUser = urlParams.get('internal') === 'true';
    const [errorText, setErrorText] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [phoneData, setPhoneData] = useState<IOnChangeEvent | null>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ ,setPhoneNumber] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
    const [ ,setPhoneCountryTwoLetterCode] = useLocalStorage<string | null>(userPhoneCountryTwoLetterCodeLocalStorageKey, null);
    const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');

    const onChange = (value: IOnChangeEvent) => {
        setPhoneData(value);

    }

    const getLegalText = () => {
        const legalText = t('loginWithPhoneLegalText')
            .replace('[TERMS_OF_SERVICE]', `<a href=${termsOfServiceLink} target="_blank" rel="noreferrer">${t('termsOfServiceLinkText')}</a>`)
            .replace('[PRIVACY_POLICY]', `<a href=${privacyPolicyLink} target="_blank" rel="noreferrer">${t('privacyPolicyLinkText')}</a>`);
        return legalText;
    }

    const onSubmit = () => {
        const isMobileOrEmbeddedBrowser = isEmbeddedBrowser();
        if (magicLinkRes.status === EAPIStatus.PENDING) return;
        setErrorText('');
        dispatch(requestMagicLink({
            payload: {
                phoneNumber: phoneData!.phone,
                phoneCountryTwoLetterCode: phoneData!.country,
                redirectUri: `${baseUrl}${isInternalUser ? APP_ROUTING_PATHS.SSO_MAGIC_LINK_INSIDERS : APP_ROUTING_PATHS.SSO_MAGIC_LINK}`,
                registrationParameters: localStorage.getItem(registrationParametersStorageKey) || undefined,
                isShortCodeRequested: !isMobileOrEmbeddedBrowser
            },
            api: API_ROUTES.AUTH.REQUEST_MAGIC_LINK_PHONE
        })).unwrap().then((res) => {
            if (res) {
                setPhoneCountryTwoLetterCode(phoneData!.country);
                setPhoneNumber(phoneData!.phone);
                setLoginType("phone")
                setViewType(isMobileOrEmbeddedBrowser ? EMagiLinkLoginPageViewType.MAGIC_LINK_SENT : EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP);
            }
        }).catch(() => {setErrorText(t('enterValidPhoneNumberError').toString())});
    }

    return (
        <>
            {viewType === EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP ? <PhoneNumberVerificationWrapper isLoginWithPhoneFlow={true} /> : <div className={`login-with-phone-page login-with-phone-page--view-type-${viewType} auth-screen`} id="login-with-phone-page">
                <ContentFrameWrapper className='login-with-phone-frame-wrapper'>
                    <div className="login-with-phone-content-container scroll-y-container-hidden-scroll-bar">
                        {viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK && <>
                            <form onSubmit={e => { e.preventDefault(); onSubmit() }} className="login-with-phone-text-and-phone-content-container">
                                <div className="login-with-phone-text-container">
                                    <h1 className="login-with-phone-title auth-title">{t('addPhoneRegistrationScreenTitle')}</h1>
                                    <p className="login-with-phone-instructions white-space-pre-wrap">{t('loginWithPhoneText')}</p>
                                </div>
                                <AppPhoneControl
                                    className='login-with-phone-control' isInternalUser={isInternalUser}
                                    errorText={errorText}
                                    onChange={onChange}
                                    onPhoneValid={setIsPhoneValid} />
                                <button onClick={onSubmit} className='auth-next-btn' form="update-phone-form" id="login-with-phone-submit" data-testid="update-phone-form-submit" type='button' disabled={!isPhoneValid || magicLinkRes.status === EAPIStatus.PENDING} >{t('sendButtonText')}</button>
                            </form>
                            <p className="login-with-phone-legal-text" dangerouslySetInnerHTML={{ __html: getLegalText() }} />
                        </>}
                        {viewType === EMagiLinkLoginPageViewType.MAGIC_LINK_SENT && <>
                            <h1 className="login-with-phone-title">{t('loginWithPhoneSecondaryTitle')}</h1>
                            <p className="login-with-phone-instructions white-space-pre-wrap">{t('loginWithPhoneSecondaryText')}</p>
                            <p className="login-with-phone-instructions login-with-phone-resend-instructions">{t('loginWithPhoneResentCodeText')}{' '}<span className="clickable-element" onClick={onSubmit}>{t('loginWithPhoneResentLinkText')}</span>.</p>
                        </>}
                    </div>
                </ContentFrameWrapper>
                <Link to={APP_ROUTING_PATHS.SIGN_IN} onClick={() => { navigate(-1) }} className='back-link'>{t('registerScreenBackButtonText')}</Link>
            </div>}
        </>
    )
}