import React from 'react'
import { getItemFromSessionOrLocalStorage } from '../../../../shared/utils/getItemFromSessionOrLocalStorage'
import { isNewSignUpFlowLocalStorageKey } from '../../../constants'
import { LoginWithPhonePage } from './LoginWithPhonePage'
import NewLoginWithPhone from '../../shorten-onboarding-flow/new-login-with-phone/NewLoginWithPhone'

const LoginWithPhonePageWrapper = () => {
  const renderContent = () => {
    if (getItemFromSessionOrLocalStorage<boolean>(isNewSignUpFlowLocalStorageKey)) {
      return <NewLoginWithPhone />
    }
    return <LoginWithPhonePage />
  }

  return (
    renderContent()
  )
}

export default LoginWithPhonePageWrapper