import React from 'react'
import { useFormContext } from 'react-hook-form';
import { onResizeTextareaHeightByTheContext } from '../../../../../../../shared/utils/utils';
import { useTranslation } from 'react-i18next';
import { IAddEditTaskForm } from '../../stageTasks.interface';
import backIcon from "../../../../../../../assets/images/thicker-2px-stroke-back-icon.svg";
import "./EditTaskNotesModal.scss";
import AppButton from '../../../../../../../shared/components/app-button/AppButton';

const EditTaskNotesModal = ({ onCloseModal, isReadOnly }: { onCloseModal: () => void, isReadOnly:boolean }) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const { t } = useTranslation();

  const onClear = () => {
    taskForm.setValue("notes", "");
  }


  return (
    <div className='edit-notes-modal--container'>
      <div className='edit-notes-modal--actions'>
        <AppButton type='button' className="edit-notes-modal--actions--back" onClick={onCloseModal} id="task-notes-modal-back-action"><img src={backIcon} alt="back" /></AppButton>
        {!isReadOnly && <AppButton id="task-notes-modal-clear-action" type='button' className="edit-notes-modal--actions--clear" onClick={onClear}>{t("clearButtonText")}</AppButton>}
      </div>
      <textarea
        {...taskForm.register("notes", { required: false, minLength: 1 })}
        className='edit-notes-modal--text-box'
        placeholder={t("addEditTaskNotesFullPageModalPlaceholder")}
        disabled={isReadOnly}
        onInput={(e) => onResizeTextareaHeightByTheContext(e?.currentTarget)}
      />
    </div>
  )
}

export default EditTaskNotesModal
