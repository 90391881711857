import { getItemFromSessionOrLocalStorage } from "../../shared/utils/getItemFromSessionOrLocalStorage";
import { APP_ROUTING_PATHS, tokenLocalStorageKey } from "../constants";

export const baseUrl = window.location.origin;
export const noRedirectLocalStorageKey = 'noRedirect';
const authUrls = [baseUrl+APP_ROUTING_PATHS.SIGN_UP_OPEN, baseUrl+APP_ROUTING_PATHS.SIGN_IN, baseUrl+APP_ROUTING_PATHS.SIGN_UP, baseUrl+APP_ROUTING_PATHS.SIGN_UP_OPEN, baseUrl+APP_ROUTING_PATHS.SIGN_UP_INSIDERS, baseUrl+APP_ROUTING_PATHS.SSO, baseUrl+APP_ROUTING_PATHS.SSO_MAGIC_LINK, baseUrl+APP_ROUTING_PATHS.MAGIC_LINK_PHONE, baseUrl+APP_ROUTING_PATHS.SSO_MAGIC_LINK_INSIDERS, baseUrl+APP_ROUTING_PATHS.SIGN_UP_SIGN_IN, baseUrl+APP_ROUTING_PATHS.MORE_INFO, baseUrl+APP_ROUTING_PATHS.MAGIC_LINK_EMAIL];

export const setNoRedirectFlagInLocalStorage = () => {
    localStorage.setItem(noRedirectLocalStorageKey, '1');
}

export const redirectNewUserToOnboarding = () => {
    const token = getItemFromSessionOrLocalStorage<string>(tokenLocalStorageKey);
    if (token) {
        setNoRedirectFlagInLocalStorage();
        return;
    }
    if (noRedirectLocalStorageKey in localStorage || authUrls.includes(window.location.href.split('?')[0])) {
        return;
    }
    window.location.href = 'https://join.maximallearning.com/';
}

export const isEmbeddedBrowser = (): boolean => {
    const ua = navigator.userAgent;
    return ua.includes('Instagram') || ua.includes('FBAN') || ua.includes('FBAV');
  }