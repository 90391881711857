import React from 'react'
import { getItemFromSessionOrLocalStorage } from '../../../../shared/utils/getItemFromSessionOrLocalStorage'
import { isNewSignUpFlowLocalStorageKey } from '../../../constants'
import { AddUserPhoneRegistrationForm } from './AddUserPhoneRegistrationForm'
import NewAddUserPhoneRegistrationForm from '../../shorten-onboarding-flow/new-add-phone-registration-form/NewAddUserPhoneRegistrationForm'

const AddPhoneRegistrationWrapper = () => {

  const renderContent = () => {
    if (getItemFromSessionOrLocalStorage<boolean>(isNewSignUpFlowLocalStorageKey)) {
      return <NewAddUserPhoneRegistrationForm />
    }
    return <AddUserPhoneRegistrationForm />
  }

  return (
    renderContent()
  )
}

export default AddPhoneRegistrationWrapper