import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { Controller, useForm } from 'react-hook-form';
import OtpInput from '../../../../shared/components/otp-input/OtpInput';
import { Link } from 'react-router-dom';
import { APP_ROUTING_PATHS, userInfoLocalStorageKey, userPhoneCountryTwoLetterCodeLocalStorageKey, userPhoneNumberLocalStorageKey } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useLocalStorage } from '../../../../shared/utils/useLocalStorage';
import { IUser } from '../../auth.interfaces';
import { useTranslation } from 'react-i18next';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';
import { ApplicationInsightsApi } from '../../../../application-insights';
import { loginReqAction, sendOtpReqAction, verifyOtpReqAction } from '../../auth.store';
import { useApiData } from '../../../../shared/hooks/useApiData';
import phoneMascotImg from '../../../../assets/images/mascot/phone-mascot.png';
import './NewPhoneNumberVerification.scss';

interface IProps {
  isLoginWithPhoneFlow?: boolean;
}

const NewPhoneNumberVerification: FunctionComponent<IProps> = ({ isLoginWithPhoneFlow }) => {
  const { control, handleSubmit, setFocus, formState, reset } = useForm<{ otp: string }>({
    defaultValues: { otp: '' }
  });
  const { verifyOtpRes } = useAppSelector(store => store.authReducer);
  const [phoneNumber,] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const [, setUserInfo] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [phoneCountryTwoLetterCode,] = useLocalStorage<string | null>(userPhoneCountryTwoLetterCodeLocalStorageKey, null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithSearchParams();
  const resetOtpTimer = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);

  useEffect(() => {
    // set auto focus on the otp input
    setFocus('otp');

    return () => {
      // clear Timeout when the component is destroyed
      if (resetOtpTimer.current) clearTimeout(resetOtpTimer.current);
    }
  }, [setFocus]);

  const onSubmit = (formData: { otp: string }) => {
    if (isLoginWithPhoneFlow) {
      dispatch(loginReqAction({ payload: { accessToken: formData.otp, redirectUri: "", error: "", phoneNumber: phoneNumber }, loginType: "phone" }))
        .unwrap()
        .catch(e => {
          ApplicationInsightsApi.trackException(e);
          setShouldDisplayError(true);
          resetOtpInput();
        });
    } else {
      if (phoneNumber) {
        dispatch(verifyOtpReqAction({ phoneNumber: `${phoneNumber}`, otp: formData.otp, phoneCountryTwoLetterCode: `${phoneCountryTwoLetterCode}` }))
          .unwrap().then((data) => {
            setUserInfo(data);
            navigate(APP_ROUTING_PATHS.REDIRECT);
          });
      }
    }
  }

  useApiData(verifyOtpRes, {
    // reset otp input when verification API rejected
    onRejected() {
      setShouldDisplayError(true);
      resetOtpInput();
    },
  });

  const resetOtpInput = () => {
    if (resetOtpTimer.current) clearTimeout(resetOtpTimer.current);
    resetOtpTimer.current = setTimeout(() => {
      setFocus('otp');
      reset({ 'otp': '' });
    }, 500);
  }

  const resendCode = () => {
    dispatch(sendOtpReqAction({ phoneNumber: `${phoneNumber}` }));
    setShouldDisplayError(false);
  }

  return (
    <div className="phone-verification-page shorten-onboarding-flow auth-screen" id="phone-verification-page" data-testid="phone-verification-page">
      <ContentFrameWrapper className='phone-verification-frame-wrapper'>
        <form className='scroll-y-container-hidden-scroll-bar' id="phone-verification-form" onSubmit={handleSubmit(onSubmit)} data-testid="phone-verification-form">
          <div className='phone-verification-form-content'>
            <div className='title-container'>
              <img src={phoneMascotImg} alt="mascot" className='mascot' />
              <h1 className='title'>{t('phoneVerificationScreenTitleNewFlow')}</h1>
            </div>            
            <p className='phone-verification-text'>{t("phoneVerificationTextNewFlow")}</p>
            <p className='phone-verification-text phone-number-to-verify'>{phoneNumber}</p>
            <p className='phone-verification-enter-code'>{t("phoneVerificationEnterCodeText")}</p>
            <div className="registration-input-container">
              <Controller
                name='otp'
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <OtpInput
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {shouldDisplayError && <small className='error' data-testid="register-error">{t("phoneVerificationPhoneNumberError")}</small>}
            </div>
            {!isLoginWithPhoneFlow && <p className='phone-verification-resend-code'>{t("phoneVerificationResendCodeText")} &nbsp;<span onClick={resendCode}>{t("phoneVerificationResendNow")}</span></p>}
          </div>
          <div className="next-container">
            <button className='auth-next-btn' form="phone-verification-form" id="phone-verification-form-submit" data-testid="phone-verification-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted} >{t('welcomeScreenButtonText')}</button>
          </div>
        </form>
      </ContentFrameWrapper>
      <Link onClick={() => navigate(-1)} to={APP_ROUTING_PATHS.ADD_PHONE_REGISTER} className='back-link'>{t('registerScreenBackButtonText')}</Link>
    </div>
  )
}

export default NewPhoneNumberVerification